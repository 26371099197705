<template>
  <div class="container-fluid">
    <div class="row m-0">
      <div class="col-lg-2">
        <h5 class="mt-5 mb-3">
          <strong>Evaluadores</strong>
        </h5>
        <router-link
          :to="{ name: 'crear.evaluador' }"
          class="btn btn-primary btn-sm cursor-pointer rounded-lg"
          >Crear evaluador</router-link
        >
      </div>
      <loading v-if="estadoloading" />
      <div
        class="col-lg-10"
        style="height: calc(100vh - 113px); overflow-y: scroll"
        v-else
      >
        <div class="row mt-3">
          <div
            class="col-lg-3 mb-3"
            v-for="evaluador in evaluadores"
            :key="evaluador.id"
          >
            <div class="card">
              <div class="card-body">
                <div class="d-flex justify-content-center mt-3">
                  <img
                    :src="url + evaluador.foto"
                    alt
                    width="150"
                    height="130"
                    class="rounded"
                    v-if="evaluador.foto != null"
                  />
                  <img
                    src="@/assets/img/sinfoto.png"
                    alt
                    width="150"
                    height="130"
                    v-else
                  />
                </div>
                <p class="text-center mt-1 mb-0">
                  <strong>{{ evaluador.fullname }}</strong>
                </p>
                <p class="text-center">{{ evaluador.profesion }}</p>
                <div class="d-flex justify-content-center">
                  <router-link
                    :to="{
                      name: 'editar.evaluador',
                      params: { id: evaluador.id },
                    }"
                    class="btn btn-primary btn-sm cursor-pointer rounded-lg w-75"
                    >Ver</router-link
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import loading from "@/components/Loading";
export default {
  name: "evaluadores",
  data() {
    return {
      url: process.env.VUE_APP_RUTA_IMG,
    };
  },
  components: {
    loading,
  },
  mounted() {
    this.$store.dispatch("fetchEvaluadores");
  },

  computed: {
    evaluadores() {
      return this.$store.getters.getEvaluadores;
    },
    estadoloading() {
      return this.$store.getters.getEstatusLoading;
    },
  },
};
</script>
